:root {
  --color: #a97142;
}



.MuiFab-root:hover,
.MuiFab-root:focus,
.MuiFab-root:active {
  background-color: #262626 !important;
  color: #ff4c42 !important;
  border: 2px solid #ff4c42 !important;
}

@media only screen and (max-width: 899px) {
  .App{
    max-width: 100% !important;
    overflow-x: hidden;
  }
  
}


.active {
  border-bottom: 1px solid #ff3131;
}

.services-box:hover{
  transform: scale(1.1);


}

.App{
  max-width: 100% !important;
  overflow-x: hidden;
}